import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';

import * as fromRoot from './state/app.reducer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'papageno';
  isNavigationVisible$: Observable<boolean>;
  isSideBarVisible$: Observable<boolean>;
  isHomePage: boolean;

  constructor(
    private store: Store<fromRoot.AppState>,
    private router: Router
  ) {
    
    this.router.events.pipe(filter(event => event instanceof NavigationStart))
      .subscribe((r: NavigationStart) => {
        this.isHomePage = (r.url === '/home' || r.url === '');
      });
  }

  ngOnInit(): void {
    this.isNavigationVisible$ = this.store.pipe(select(fromRoot.getNavigationState));
    this.isSideBarVisible$ = this.store.pipe(select(fromRoot.getSideBarState));
  }
}
