import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as App from './app.actions';

export interface AppState {
  isNavigationVisible: boolean;
  isSideBarVisible: boolean;
}

const initialState: AppState = {
  isNavigationVisible: false,
  isSideBarVisible: false
};

export function appReducer(
  state: AppState = initialState,
  action: App.Actions ): AppState {
  switch (action.type) {
    case App.ActionTypes.ShowSideBar: {
      return {
        ...state,
        isSideBarVisible: true
      };
    }
    case App.ActionTypes.HideSideBar: {
      return {
        ...state,
        isSideBarVisible: false
      };
    }
    case App.ActionTypes.ToggleSideBar: {
      return {
        ...state,
        isSideBarVisible: !state.isSideBarVisible
      };
    }
    case App.ActionTypes.ShowNavigation: {
      return {
        ...state,
        isNavigationVisible: true
      };
    }
    case App.ActionTypes.HideNavigation: {
      return {
        ...state,
        isNavigationVisible: false
      };
    }
    case App.ActionTypes.ToggleNavigation: {
      return {
        ...state,
        isNavigationVisible: !state.isNavigationVisible
      };
    }

    default: {
      return state;
    }
  }
}

const getAppFeatureState = createFeatureSelector<AppState>('app');

export const getSideBarState = createSelector(
  getAppFeatureState,
  (state: AppState) => state.isSideBarVisible
);
export const getNavigationState = createSelector(
  getAppFeatureState,
  (state: AppState) => state.isNavigationVisible
);
