import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';
import { filter, map } from 'rxjs/operators';

import * as fromRoot from '../../state/app.reducer';
import * as AppActions from '../../state/app.actions';
import { HeaderService } from '../header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isNavigationVisible$: Observable<boolean>;
  showHeaderDetails$: Observable<boolean>;
  
  constructor(
    private store: Store<fromRoot.AppState>,
    public headerS: HeaderService,
    private router: Router
  ) {
    this.showHeaderDetails$ = this.router.events.pipe(filter(event => event instanceof NavigationStart))
    .pipe(map((val: NavigationStart) => {
      const arr = val.url.split('/');
      return arr.length >= 2 && arr[1] === 'tree-map';
    }));
  }

  ngOnInit(): void {
    this.isNavigationVisible$ = this.store.pipe(select(fromRoot.getNavigationState));
  }
  
  toggleNavigation(): void {
    this.store.dispatch( new AppActions.ToggleNavigation());
  }

  toggleSideBar(): void {
    this.store.dispatch( new AppActions.ToggleSideBar());
  }

}
