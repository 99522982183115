import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  data$: Subject<any>;
  count$: Subject<number>;
  currentCount$: Subject<number>;
  constructor() {
    this.data$ = new Subject();
    this.count$ = new Subject();
    this.currentCount$ = new Subject();
  }
  setData(data: any): void {
    this.data$.next(data);
  }
  setCount(data: number): void {
    this.count$.next(data);
  }
  setCurrentCount(data: number): void {
    this.currentCount$.next(data);
  }
}
