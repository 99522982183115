import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HomeComponent } from './home/home.component';
import { HilfeComponent } from './hilfe/hilfe.component';
import { WissenComponent } from './wissen/wissen.component';
import { ImpressumComponent } from './impressum/impressum.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'wissen', 
    component: WissenComponent
  },
  { path: 'hilfe', 
    component: HilfeComponent
  },
  { path: 'impressum', 
    component: ImpressumComponent
  },
  {
    path: 'tree-map',
    loadChildren: './tree-map/tree-map.module#TreeMapModule'
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
