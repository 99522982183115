import { Component, OnInit } from '@angular/core';
import { transition, style, group, animate, stagger, query, trigger } from '@angular/animations';

@Component({
  animations: [
    trigger('page', [
      transition(':enter', [
        style({ transform: 'scale(0.8)', opacity: 0 }), 
        query('.animated--item', [
          style({
            opacity: 0,
            transform: 'translateX(-10px)'
          })
        ]), // initial
        group([
          animate('.4s cubic-bezier(.8, -0.6, 0.2, 1.5)', 
          style({ transform: 'scale(1)', opacity: 1 })), // final,
          query('.animated--item', [
            stagger(100, [
              animate('1s .2s cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'none' }))
            ])
          ])
        ])
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 0 }),  // initial
        group([
          animate('.4s cubic-bezier(.8, -0.6, 0.2, 1.5)', 
          style({ transform: 'scale(0.8)', opacity: 1 })) // final,
        ])
      ])
    ])
  ],
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
