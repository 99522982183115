import { animate, style, group, query, stagger } from '@angular/animations';

export const InAnimation = [
  style({
    minWidth: '100vw',
    width: '100vw',
    minHeight: '50vh',
    height: '50vh',
    borderRadius: '0px 0px 0px 100%'
  }),
  query('.navigation--item', [
    style({
      opacity: 0,
      transform: 'translateX(-10px)'
    })
  ]),
  group([
    animate('.4s ease', style({
      minHeight: '100vh',
      height: '100vh'
    })),
    animate('.3s ease-in-out', style({
      borderRadius: '0px 0px 0px 0px'
    })),
    query('.navigation--item', [
      stagger(-100, [
        animate('.5s .4s cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'none' }))
      ])
    ])
  ])
];
