import { Action } from '@ngrx/store';

export enum ActionTypes {
  ShowSideBar = '[ App ] Show Side Bar',
  HideSideBar = '[ App ] Hide Side Bar',
  ToggleSideBar = '[ App ] Toggle Side Bar',
  ShowNavigation = '[ App ] Show Navigation',
  HideNavigation = '[ App ] Hide Navigation',
  ToggleNavigation = '[ App ] Toggle Navigation'
}

export class ShowSideBar implements Action {
  readonly type = ActionTypes.ShowSideBar;
}

export class HideSideBar implements Action {
  readonly type = ActionTypes.HideSideBar;
}

export class ToggleSideBar implements Action {
  readonly type = ActionTypes.ToggleSideBar;
}

export class ShowNavigation implements Action {
  readonly type = ActionTypes.ShowNavigation;
}

export class HideNavigation implements Action {
  readonly type = ActionTypes.HideNavigation;
}

export class ToggleNavigation implements Action {
  readonly type = ActionTypes.ToggleNavigation;
}

export type Actions = ShowSideBar | HideSideBar | ToggleSideBar | ShowNavigation | HideNavigation | ToggleNavigation;
