import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { trigger, transition } from '@angular/animations';

import * as fromRoot from '../../state/app.reducer';
import * as AppActions from '../../state/app.actions';
import * as NavigationAnimations from './animations';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [
    trigger('navigationAnimation', [
      transition(':enter', NavigationAnimations.InAnimation)
    ])
  ]
})
export class NavigationComponent implements OnInit {
  
  constructor(
    private store: Store<fromRoot.AppState>
  ) { }

  ngOnInit(): void {
  }

  hideNavigation(): void {
    this.store.dispatch( new AppActions.HideNavigation());
  }
}
