import { Component, OnInit } from '@angular/core';
import { trigger, transition } from '@angular/animations';

import * as SidebarAnimations from './animations';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
  animations: [
    trigger('sidebarAnimation', [
      transition(':enter', SidebarAnimations.InAnimation),
      transition(':leave', SidebarAnimations.OutAnimation)
    ])
  ]
})
export class SideBarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
