import { animate, style, group, query, stagger } from '@angular/animations';

export const InAnimation = [
  style({
    opacity: 0,
    transform: 'translateX(100%)'
  }),
  query('.sidebar--item', [
    style({ opacity: 0, transform: 'translateY(-10px)' })
  ]),
  group([
    animate('.3s ease-in', style({
      opacity: 1,
      transform: 'translateX(0%)'
    })),
    query('.sidebar--item', [
      stagger(-100, [
        animate('.3s cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'none' }))
      ])
    ])
  ])
];

export const OutAnimation = [
  style({
    transform: 'translateX(0%)'
  }),
  animate('.8s ease-out', style({
    opacity: 0,
    transform: 'translateX(100%)'
  }))
];
