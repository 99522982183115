import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { filter, distinctUntilChanged, map } from 'rxjs/operators';

import { Breadcrumb } from './breadcrump';

@Component({
  selector: 'app-breadcrump',
  templateUrl: './breadcrump.component.html',
  styleUrls: ['./breadcrump.component.scss']
})
export class BreadcrumpComponent implements OnInit {
  breadcrumbs$;
  constructor(
    private router: Router
  ) {
    this.breadcrumbs$ = this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .pipe(distinctUntilChanged())
      .pipe(map((event: NavigationStart) => {
        const arr = event.url.split('/').filter(r => r !== '');

        return this.parseBreadcumb(arr);
      }));
  }

  ngOnInit(): void {
  }

  parseBreadcumb(arr: string[]): Breadcrumb[] {
    let str = '';
    return arr.map(b => {
      str = str + '/' + b;
      const brdcb: Breadcrumb = {
        label: b,
        url: str
      };
      return brdcb;
    });
  }

}
