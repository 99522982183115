import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import * as fromRoot from '../state/app.reducer';
import * as AppActions from '../state/app.actions';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isNavigationVisible$: Observable<boolean>;
  
  constructor(
    private store: Store<fromRoot.AppState>
    ) {
    this.isNavigationVisible$ = this.store.pipe(select(fromRoot.getNavigationState));
  }

  ngOnInit(): void {
  }
  
  toggleNavigation(): void {
    this.store.dispatch( new AppActions.ToggleNavigation());
  }

}
